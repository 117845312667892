import React from 'react';
const Career = () => {
    return ( 
        <div style={{padding: '100px'}}>
            <h1>
                Coming Soon
            </h1>
        </div>
     );
}
 
export default Career;