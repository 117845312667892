import React, { useRef }  from 'react';
import emailjs from 'emailjs-com';
import { Button, Input, TextArea } from 'semantic-ui-react';
import './footer.scss';
import logo from '../../assets/logo.png'

const Footer = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_zutu94b', 'template_kyjichm', form.current, process.env.REACT_APP_EMAIL_KEY)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    return ( 
        <div className='footer-container'>
            <h1 className='form-container'>Let's Connect</h1>
            <div >
                <form ref={form} onSubmit={sendEmail}>
                    <Input className='controls' type="text" name="user_name" placeholder='Name'/>
                    <br />
                    <Input className='controls' type="email" name="user_email" placeholder='Email'/>
                    <br />
                    <TextArea style={{height: '140px'}} className='controls' name="message" placeholder='Tell us'/>
                    <br />
                    <Button style={{
                        color: '#FFF', 
                        backgroundColor: '#6EACDA',
                        marginTop: '20px'
                        }} className='controls' type="submit" value="Send" >Send Email</Button>
                </form>
            </div>
            
            <div className='powered-section'>
                <img src={logo} style={{width: '40%'}} alt='logo'/>
            </div>
            <div >
                Powered by IntelliNeurals
            </div>
        </div>
     );
}
 
export default Footer;