import React from 'react';
import next from '../../assets/img1.jpg'
import whowe from '../../assets/team.jpg'
import chooseUs from '../../assets/ch.jpg'
import values from '../../assets/values.jpg'
import './About.scss'
const About = () => {
    return ( 
        <div className='about-container'>
            <div className='who-we-are'>
                <div className='side-text'>
                    <h1 className='title'>Who We Are?</h1>
                    <p className='description'>
                    At Intellineurals, we are committed to transforming the way businesses operate by delivering cutting-edge IT consulting and recruitment services. Our team of seasoned professionals brings a wealth of experience and expertise to the table, ensuring that we provide top-notch solutions tailored to meet the unique needs of our clients.
                    </p>
                    <h1 className='title'>Our Mission</h1>
                    <p className='description'>
                    Our mission is to empower organizations to achieve their full potential by leveraging technology and talent. We believe in the power of innovation and the impact it can have on business growth and success. Whether it's optimizing your IT infrastructure or finding the right talent to drive your business forward, we are here to help.
                    </p>
                </div>
                <div className='side-image'>
                    <img className='image' src={whowe}/>
                </div>
            </div>
            <div className='who-we-are'>
                <div className='side-image'>
                    <img className='image' src={next}/>
                </div>
                <div className='side-text'>
                    <h1 className='title'>What We Do?</h1>
                    <h2>IT Consulting:</h2>
                    <p className='description'>                    
Our IT consulting services are designed to help businesses navigate the complex world of technology. From strategy development to implementation, we offer comprehensive solutions that drive efficiency and innovation. Our areas of expertise include cloud computing, cybersecurity, data analytics, and more.
                    </p>
                    <h2>Recruitment Services:</h2>
                    <p className='description'>                    
                    Finding the right talent can be a daunting task. At Intellineurals, we simplify the process by connecting you with highly skilled professionals who are the perfect fit for your organization. Our recruitment services cover a wide range of IT roles, from software developers and system architects to project managers and IT support specialists.
                    </p>
                </div>                
            </div>
            <div className='who-we-are'>
                <div className='side-text'>
                    <h1 className='title'>Why Choose Us?</h1>
                    <h2>Expertise</h2>
                    <p className='description'>
                    Our team consists of industry veterans who bring deep knowledge and insight to every project.
                    </p>
                    <h2>Customized Solutions</h2>
                    <p className='description'>
                    We understand that every business is unique. Our solutions are tailored to meet your specific needs and goals.
                    </p>
                    <h2>Commitment to Excellence:</h2>
                    <p className='description'>
                    We are dedicated to delivering high-quality services that exceed your expectations.
                    </p>
                    <h2>Innovative Approach</h2>
                    <p className='description'>
                    We stay ahead of the curve by continuously exploring new technologies and methodologies.
                    </p>
                </div>
                <div className='side-image'>
                    <img className='image' src={chooseUs}/>
                </div>
            </div>
            <div className='who-we-are'>
                <div className='side-image'>
                    <img className='image' src={values}/>
                </div>
                <div className='side-text'>
                    <h1 className='title'>Our Values</h1>
                    <h2>Integrity:</h2>
                    <p className='description'>                    
                    We conduct our business with the highest level of honesty and transparency.
                    </p>
                    <h2>Collaboration:</h2>
                    <p className='description'>                    
                    We work closely with our clients to ensure their success.
                    </p>
                    <h2>Innovation:</h2>
                    <p className='description'>                    
                    We embrace change and strive to be at the forefront of technological advancements.
                    </p>
                    <h2>Excellence:</h2>
                    <p className='description'>                    
                    We are committed to achieving excellence in everything we do.
                    </p>
                </div>                
            </div>
            <div className='who-we-are'>   
                <div className='side-text'>
                <h1 className='title'>Get in Touch</h1>
                <h2 className='description'>Ready to take your business to the next level? Contact us today to learn more about how Intellineurals can help you achieve your IT and recruitment goals.</h2>          
                </div>
            </div>
        </div>
     );
}
 
export default About;