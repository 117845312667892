import React, { useCallback } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import './home.scss';
import shearshare from '../../assets/shearshare.png';
import sada from '../../assets/sada.png';
import degree from '../../assets/66degrees.png'
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();
    const handleItemClick = useCallback(() => {
        navigate('contact')
    }, [navigate])
    return ( 
        <div className='home-container'>
            <div className='welcome-section'>
                <div>
                    <h1 className='title'>
                        Engineering Excellence For Business Excellence
                    </h1>
                </div>
                <div className='description'>
                    We enable meaningful, measurable impact—and deliver business outcomes that create the future you want.
                </div>
                <div className='connect'>
                    <Button className='btn' onClick={handleItemClick}>Let's Connect</Button>
                </div>
            </div>
            <div className='features'>
                <div className='description'>
                    FEATURES
                </div>
                <div className='ques'>
                    <h1 className='ques-title'>
                        What makes 'IntelliNeurals', the best partner for excellent outcome.
                    </h1>
                </div>
                <h2 className='subheading'>
                    IntelliNeurals offer end to end project services starting from recruiting, staffing, IT consulting to support and maintainance with commitment of excellent delivery.
                </h2>
                <div className='services'>
                    <div className='item'>
                        <Icon name='server'/>
                        <h1>
                            IT Consulting
                        </h1>
                        <h3 className='description'>
                        Professionals provide expert advice to organizations achieve their business objectives. IT consultants work with clients to improve their 
                        </h3>
                        <ul className='services-list'>
                            <li>IT infrastructure</li>
                            <li>Streamline processes</li>
                            <li>Enhance cybersecurity</li>
                            <li>Implement new technologies</li>
                        </ul>
                    </div>
                    <div className='item'>
                        <Icon name='users'/>
                        <h1>
                            Recruitement
                        </h1>
                        <h3 className='description'>
                        Focused on identifying and attracting potential talent to fill specific positions.                        
                        </h3>
                        <ul className='services-list'>
                            <li>Job Analysis</li>
                            <li>Sourcing</li>
                            <li>Advertising</li>
                            <li>Screening</li>
                            <li>Interviewing</li>
                        </ul>
                    </div>
                    <div className='item'>
                        <Icon name='book'/>
                        <h1>
                            Training
                        </h1>
                        <h3 className='description'>
                            Skills enhancement for new projects. Train the graduats and staff for fulfulling transformation needs
                        </h3>
                        <ul className='services-list'>
                            <li>Dotnet Microservices</li>
                            <li>Microfronends</li>
                            <li>React</li>
                            <li>Angular</li>
                            <li>React Native</li>
                            <li>Cloud Services</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='clients'>
                <h1>Experienced working with clients</h1>
                <div>
                    <img className='images' src={degree}/>
                    <img className='images' src={sada}/>
                    <img className='images' src={shearshare}/>
                </div>
            </div>
        </div>
     );
}
 
export default Home;