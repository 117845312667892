import React, { useCallback, useState } from 'react'
import { MenuMenu, MenuItem, Menu, Icon } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import './header.scss'
import logo from '../../assets/logo.png'

const Header = () => {
    const [activeItem, setActiveItem] = useState('home');
    const [innerWidth, setInnerWidth] =useState(window.innerWidth);
    const [showMenu, setShowMenu] = useState(window.innerWidth >= 480)
    const navigate = useNavigate();
    const handleItemClick = useCallback((e, {name}) => {
        setActiveItem(name);
        navigate(name)
    }, [navigate])

    const handleShow = useCallback(() => {
        setShowMenu(!showMenu);
    }) 
    return (
        <div className="header-container">
            <div className='header-logo'>
                <img className='image' src={logo} alt='logo' />
            </div>
            {
                innerWidth <= 480 &&
            <div>
                <Icon name={showMenu ? 'close' : 'align justify'} 
                style={{color: '#FFF', fontSize: '1.2rem', marginTop: '20px'}} 
                onClick={handleShow}/>
            </div>
            }
            {   showMenu &&
                <div className='header-menu'>
                    <Menu pointing secondary vertical={innerWidth <= 480}>
                        <MenuItem
                            name='home'
                            active={activeItem === 'home'}
                            onClick={handleItemClick}                    
                        />
                        <MenuItem
                            name='about'
                            active={activeItem === 'about'}
                            onClick={handleItemClick}
                        />
                        <MenuItem
                            name='services'
                            active={activeItem === 'services'}
                            onClick={handleItemClick}
                        />
                        <MenuItem
                            name='products'
                            active={activeItem === 'products'}
                            onClick={handleItemClick}
                        />
                        <MenuItem
                            name='work'
                            active={activeItem === 'work'}
                            onClick={handleItemClick}
                        />
                        <MenuItem
                            name='blog'
                            active={activeItem === 'blog'}
                            onClick={handleItemClick}
                        />
                        <MenuItem
                            name='career'
                            active={activeItem === 'career'}
                            onClick={handleItemClick}
                        />
                        <MenuItem
                            name='contact'
                            active={activeItem === 'contact'}
                            onClick={handleItemClick}
                        />
                        {
                            innerWidth >= 480 &&
                        <MenuMenu position='right' style={{paddingLeft: '200px'}}>
                            <MenuItem
                                icon='facebook'
                                onClick={handleItemClick}
                            />
                            <MenuItem
                                icon='google'
                                onClick={handleItemClick}
                            />
                            <MenuItem
                                icon='linkedin'
                                onClick={handleItemClick}
                            />
                            <MenuItem
                                icon='twitter'
                                onClick={handleItemClick}
                            />
                        </MenuMenu>
                        }
                    </Menu>
            </div>
        }   
        </div>
    )
}

export default Header;