import React from 'react';
import { Card } from 'semantic-ui-react';
import './Service.scss';
const Services = () => {
    return ( 
        <div className='service-container'>
            <h1 className='service-title'>OUR STRAINGTHS</h1>
            <div className='card-container'>
                <div className='card-style'>
                    <Card                        
                        className='card-look'
                        href='#'
                        header='Strategic IT Planning'
                        meta='IT Consulting'
                        description=' We help businesses develop long-term IT strategies that align with their goals. Our experts assess your current infrastructure, identify areas for improvement, and create a roadmap for future growth.'
                    />
                </div>
                <div className='card-style'>
                    <Card
                        className='card-look'
                        href='#'
                        header='Cloud Solutions'
                        meta='IT Consulting'
                        description='Embrace the power of the cloud with our comprehensive cloud consulting services. We assist with cloud migration, deployment, and management to ensure a seamless transition and optimal performance.'
                    />
                </div>
                <div className='card-style'>
                    <Card                        
                        className='card-look'
                        href='#'
                        header='Data Analytics'
                        meta='IT Consulting'
                        description='Unlock the full potential of your data with our data analytics services. We provide data integration, analysis, and visualization to help you make informed business decisions.'
                    />
                </div>
            </div>
            <div className='card-container'>
                <div className='card-style'>
                    <Card                        
                        className='card-look'
                        href='#'
                        header='IT Infrastructure Management'
                        meta='IT Consulting'
                        description='Ensure your IT infrastructure runs smoothly with our management services. We offer network setup, server management, and ongoing support to keep your systems up and running.'
                    />
                </div> 
                <div className='card-style'>
                    <Card   
                        className='card-look meta1'
                        href='#'
                        header='Bespoke Software Development'
                        meta='IT Consulting'
                        description='Need a unique solution? Our team of developers can create custom software tailored to your specific business needs, ensuring it integrates seamlessly with your existing systems.'
                    />
                </div> 
                <div className='card-style'>
                    <Card                        
                        className='card-look'
                        href='#'
                        header='Project Management'
                        meta='IT Consulting'
                        description='Successfully execute your IT projects with our project management services. We offer end-to-end project management, from planning and execution to monitoring and completion.'
                    />
                </div>                
            </div>  
            <div className='card-container'>
                <div className='card-style'>
                    <Card                        
                        className='card-look'
                        href='#'
                        header='Talent Acquisition'
                        meta='Recruitment Services'
                        description='Find the right talent for your organization with our comprehensive recruitment services. We specialize in sourcing, screening, and selecting candidates that match your requirements and company culture.'
                    />
                </div>
                <div className='card-style'>
                    <Card
                        className='card-look'
                        href='#'
                        header='Contract Staffing'
                        meta='Recruitment Services'
                        description='Need temporary expertise? Our contract staffing services provide you with skilled professionals for short-term projects or to fill temporary vacancies.'
                    />
                </div>
                <div className='card-style'>
                    <Card                        
                        className='card-look'
                        href='#'
                        header='Permanent Staffing'
                        meta='Recruitment Services'
                        description='Build your core team with our permanent staffing solutions. We help you find full-time employees who are the perfect fit for your long-term needs.'
                    />
                </div>
            </div>   
            <div className='card-container'>
                <div className='card-style'>
                    <Card                        
                        className='card-look'
                        href='#'
                        header='Executive Search'
                        meta='IT Consulting'
                        description='Identify and recruit top-level executives with our executive search services. We understand the critical role leadership plays in your business and are committed to finding the best candidates for your senior positions.'
                    />
                </div>                
            </div>   
            <div className='card-container'>
                <div className='card-style'>
                    <Card                        
                        className='card-look'
                        href='#'
                        header='Employee Training Programs'
                        meta='Training and Development'
                        description='Enhance your teams skills with our tailored training programs. We offer workshops and courses on various IT topics, ensuring your employees stay up-to-date with the latest technologies and best practices.'
                    />
                </div>
                <div className='card-style'>
                    <Card
                        className='card-look'
                        href='#'
                        header='Certification Preparation'
                        meta='Training and Development'
                        description='Prepare your staff for industry-recognized certifications with our comprehensive training courses. We provide study materials, practice exams, and expert guidance to help them succeed.'
                    />
                </div>                
            </div>    
        </div>
     );
}
 
export default Services;