import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home1';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import './App.scss';
import Services from './Pages/Services/Services';
import Products from './Pages/Products/Products';
import Work from './Pages/Work/Work';
import Career from './Pages/Career/Career';
import Blog from './Pages/Blog/Blog';
function App() {
  return (
      <div className="App">
        <div className='main-content'>
          <Header/>
          <Routes>
            <Route exact path="/*" Component={Home} />
            <Route path="/about" Component={About} />
            <Route path="/services" Component={Services} />
            <Route path="/products" Component={Products} />
            <Route path='/work' Component={Work}/>
            <Route path='/career' Component={Career} />
            <Route path="/blog" Component={Blog} />
            <Route path="/contact" Component={Contact} />
          </Routes>               
          <Footer/>
        </div>
      </div> 
  );
}

export default App;
